import register from 'preact-custom-element';
import { h, Component } from "preact";
import params from "@config";

class HeartsService {
    constructor(params) {
        let { hearts_service } = params;
        this.url = new URL(hearts_service);
        this.ws = this.connectWS();
        setInterval(() => this.ping(this.ws), 5000);
        this.subscribers = { };
        this.reconnect_tries = 10;
        this.attempts = 0;
    }

    connectWS() {
        let url = this.websocketURL()
        let ws = new WebSocket(url)

        ws.addEventListener('open',  (event) => {
            console.log(`connected ws ${url} `, event)
            this.attempts = 0;

            for(let id of Object.keys(this.subscribers)) {
                this.sendSubscribe(ws, id);
            }
        });

        ws.addEventListener('message', (event) => {
            for(let handler of Object.values(this.subscribers)) {
                handler(event);
            }
        });

        ws.addEventListener('close',  (event) => {
            console.log(`closed ws ${url} `, event)
            this.reconnect();
        });

        ws.addEventListener('error',  (event) => {
            console.log(`error ws ${url} `, event)
        });

        return ws;
    }

    ping(ws) {
        ws.send("")
    }

    sendSubscribe(ws, id) {
        console.log(`subscribing to ${id}`)
        ws.send(JSON.stringify({method: 'SUBSCRIBE', id}))
    }

    reconnect() {
        if (this.attempts++ < this.reconnect_tries) {
            const delay = (this.attempts ** 2 + 10);
            console.log(`Reconnecting to ${this.websocketURL()} in ${delay} seconds`);

            setTimeout(() => {
                this.ws = this.connectWS();
            }, delay * 1000)
        }
    }

    websocketURL() {
        let wsURL = new URL(this.url.toJSON());

        switch (wsURL.protocol) {
            case "http:": wsURL.protocol = "ws:"; break;
            case "https:": wsURL.protocol = "wss:"; break;
            default: throw new Error(`Unknown protocol ${wsURL.protocol}`)
        }

        return wsURL.toString();
    }

    subscribe(id, handler) {
        this.subscribers[id] = handler;

        if (this.ws.readyState === 1) {
            this.sendSubscribe(this.ws, id)
        }
    }
}

const hearts = new HeartsService(params);

const randomInt = function (min, max) {
    return Math.floor(randomFloat(min, max));
}

const randomFloat = function (min, max) {
    return Math.random() * (max - min) + min;
}

class Hearts extends Component {
    id
    update = true
    state = { loading: true, count: null, animations: { } }

    server = hearts.url
    transitionTimeSeconds = 7

    static tagName = 'x-hearts';
    static observedAttributes = ['id'];

    get url() {
        return new URL(this.props.id, this.server)
    }

    async componentDidMount() {
        const id = this.props.id

        this.subscribe(id);

        if (this.update) {
            await this.refresh();
        }
    }

    async refresh() {
        const id = this.props.id;
        const count = await this.loadHearts(id)
        this.setCount(count)
    }

    subscribe(id) {
        hearts.subscribe(id, event => {
            this.handleMessage(event)
        });
    }

    async loadHearts(id) {
        console.log(`Loading hearts for id: ${id}`)
        const response = await fetch(this.url);

        const value = await response.text();

        return parseInt(value, 10);
    }

    render() {
        return (
            <span className={`heart ${this.state.loading && "heart--loading"}`}>
                <a href="#" className="heart-symbol" onClick={this.addHeart}> </a>&nbsp;
                <span className="heart-value">{this.state.count}</span>

                {Object.entries(this.state.animations).map(([timeout, animation]) => (
                    <div className="heart-animation-box" key={timeout} style={animation.style}>
                        <div className="heart-symbol heart-animated">{timeout}</div>
                    </div>
                ))}
            </span>
        );
    }

    addHeart = async (ev) => {
        ev.preventDefault()
        const id = this.props.id
        let count = this.state.count + 1;
        this.setCount(count)

        if (this.update) {
            count = await this.incrementHeart(id);
            this.setCount(count)
        }
    };

    async incrementHeart(id) {
        console.log(`Increasing hearts for id: ${id}`)
        const response = await fetch(this.url, { method: 'POST' });

        const value = await response.text();

        return parseInt(value, 10);
    }

    handleMessage(message) {
        const payload = JSON.parse(message.data);

        switch (payload.method) {
            case 'UPDATED': return this.updated(payload)
            default: console.warn("Unknown message: " + message.data)
        }
    }

    updated(payload) {
        if (payload.id !== this.props.id) return;
        this.setCount(payload.value);
    }

    setCount(count) {
        const previousCount = this.state.count;

        console.log(`Setting ${count} (from ${previousCount}) for ${this.props.id}`)

        this.setState({ count })

        if (previousCount === null) return;

        const diff = count - previousCount;

        for(let i=0; i<diff; i++) {
            const animation = this.newAnimation()
            const animations = { ...this.state.animations, ...animation }
            this.setState({ animations })
        }
    }

    newAnimation() {
        const timeout = setTimeout(() => {
            console.log(`Finished timeout ${timeout} for heart animation`)
            const animations = { ...this.state.animations }
            delete animations[timeout]
            this.setState({ animations })
        }, this.transitionTimeSeconds * 1000)
        console.log(`Started timeout ${timeout} for heart animation`)

        return {
            [timeout]: {
                style: {
                    '--heart-animated-duration': `${randomFloat(5, 15).toPrecision(2)}s`,
                    '--heart-box-left': `${randomFloat(-4, 4)}rem`,
                    '--heart-box-height': `${randomFloat(10, 20)}rem`,
                    '--heart-box-rotation': randomFloat(-1, 1) > 0 ? "180deg" : "0",
                 },
            }
        }
    }
}

register(Hearts);
