import lunr from 'lunr/lunr';

import { h, render, Component } from 'preact';
import 'preact/compat';


class Search extends Component {
    state = { term: '', pages: [] }

    async componentDidMount() {
        const response = await fetch('/index-lunr.json')
        const json = await response.json()

        const index = lunr.Index.load(json)

        this.setState({ index })
    }

    onInput = ev => {
        const term = ev.target.value;
        this.setState({ term });
    }

    onSubmit = async ev => {
        ev.preventDefault();
        if(this.state.term.length === 0) {
            return this.reset()
        }

        const pages = await this.search()
        this.setState({ pages })
    }

    async search() {
        let term = this.state.term;
        let results = this.state.index.search(term);

        this.setState({ results })

        return await Promise.all(results.map(({ref}) => fetch(ref + 'index.json').then(r => r.json())))
    }

    reset() {
        this.setState({ results: null, term: '', pages: [] })
    }

    render() {
        return (
            <form className="d-flex" onSubmit={this.onSubmit}>
                <input className="form-control mr-1" type="search" placeholder="Hledat" aria-label="Hledat"
                       value={this.state.term} onInput={this.onInput}/>
                <button className="btn btn-outline-success" type="submit">Hledat</button>
                <span className="list-group" style="position: absolute; top: 100%; z-index: 100; left: 0px; right: auto; display: block;" role="listbox">
                        {this.state.pages.map(page => (
                            <a key={page.uri} href={page.uri} className="list-group-item list-group-item-action page-list-group" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">{ page.title }</h5>
                                    <div>{(page.tags || []).map(tag => (<small>{ tag }&nbsp;</small>))}</div>
                                </div>
                                <p className="mb-1">{ page.description }</p>
                            </a>
                        ))}
                    </span>
            </form>

        );
    }
}

render((
    <Search />
), document.getElementById('search'));
